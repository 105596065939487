<template>
  <div>
    <h2>Travel Info</h2>

    <section class="alternating-sides">
      <div class="text">
        <p class="title">
          Hotel
        </p>
        <p>
          We have reserved a block of rooms at the
          <a
            target="_blank"
            href="https://www.google.com/maps/search/?api=1&query=Residence Inn by Marriott Seattle University District, 4501 12th Ave NE, Seattle, WA 98105"
          >Residence Inn</a>
          in University District for any combination of days between August 11th and August 15th.
        </p>
        <p>
          Book before July 7th for discounted rates.
        </p>
        <!--<p class="notice">-->
        <!--  <strong>NOTE:</strong>-->
        <!--  We are currently out of  rooms with two queen beds on Thursday and Friday,-->
        <!--  but are working on fixing it! It should be updated soon.-->
        <!--</p>-->
        <p>
          <a
            target="_blank"
            class="button button-primary disabled"
          >Book in our Block!</a>
        </p>
        <p class="small">
          If you need additional days beyond the designated block,
          email
          <a
            href="mailto:Cole.Mackey@marriott.com"
            target="_blank"
          >Cole.Mackey@marriott.com</a>
          and let them know that you are booking with the
          Judge and Bell-Towne Wedding reservation.
        </p>
      </div>

      <div class="images">
        <progressive-img
          placeholder="/images-placeholder/travel-info/hotel.jpg"
          src="/images/travel-info/hotel.jpg"
          :aspect-ratio="1"
        />
      </div>
    </section>

    <section class="alternating-sides">
      <div class="text">
        <p class="title">
          Directions from Airport
        </p>
        <p>
          Take the Link Light Rail from SeaTac Airport Station to the University of Washington.
          Checkout this
          <a
            target="_blank"
            href="https://deohs.washington.edu/getting-and-sea-tac-airport"
          >University Page</a>
          for more information and options.
        </p>
      </div>

      <div class="images">
        <progressive-img
          placeholder="/images-placeholder/travel-info/light-rail.jpg"
          src="/images/travel-info/light-rail.jpg"
          :aspect-ratio="1"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TravelInfoPage',
};
</script>

<style lang="scss" scoped>
p.small {
  font-size: 1rem;
}

//p.notice {
//  font-size: 1rem;
//  color: #721c24;
//  padding: 1rem;
//  border: 1px solid #f5c6cb;
//  border-radius: 0.5rem;
//  background-color: #f8d7da;
//}
</style>
